import React, { useState, useEffect } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../css/Contact.css';

const ContactPage = () => {
  const [suggestion, setSuggestion] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!suggestion.trim() || isSubmitting) return;

    setIsSubmitting(true);
    
    try {
      await addDoc(collection(db, 'userSuggestions'), {
        content: suggestion,
        timestamp: new Date(),
      });
      setPopupMessage('Thank you, your message has been submitted successfully.');
      setIsSuccess(true);
      setSuggestion('');
    } catch (error) {
      console.error('Error adding suggestion: ', error);
      setPopupMessage('Failed to send suggestion. Please try again later.');
      setIsSuccess(false);
    } finally {
      setIsSubmitting(false);
      setShowPopup(true);
    }
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  return (
    <div className="Contact-Container">
      {showPopup && (
        <div className="popup-overlay" onClick={() => setShowPopup(false)}>
          <div className={`popup-content ${isSuccess ? 'success' : 'error'}`}>
            <p>{popupMessage}</p>
            <button 
              className="popup-close" 
              onClick={() => setShowPopup(false)}
              aria-label="Close popup"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          id="suggestion"
          value={suggestion}
          onChange={(e) => setSuggestion(e.target.value)}
          placeholder="All information submitted below is anonymous, please include contact information if necessary..."
          required
        />
        <button 
          className="submitSuggestionButton" 
          type="submit"
          disabled={isSubmitting || !suggestion.trim()}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default ContactPage;