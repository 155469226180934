import React, { MouseEventHandler } from "react";
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Adjust the path as needed

interface LoginProps {
  toggleLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ toggleLogin }) => {
  const handleLoginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider);  
      const user = result.user;
      if (!user) {
        throw new Error("No user information returned from Google login.");
      } else {
        toggleLogin();
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleLoginWithFacebook = async () => {
    try {
      const result = await signInWithPopup(auth, new FacebookAuthProvider);  
      const user = result.user;
      if (!user) {
        throw new Error("No user information returned from Facebook login.");
      }  
    } catch (error) {
      console.error("Error signing in with Facebook:", error);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="close-button" onClick={toggleLogin}>
          &times;
        </button>
        
        <div className="popup-content">
          <h2>Welcome Back</h2>
          <p className="subtitle">Sign in to continue</p>

          <button className="social-button google" onClick={handleLoginWithGoogle}>
            <span className="social-icon">
              {/* Add Google SVG icon here */}
            </span>
            Continue with Google
          </button>

          <button className="social-button facebook" onClick={handleLoginWithFacebook}>
            <span className="social-icon">
            {/* Add Facebook SVG icon here */}
            </span>
            Continue with Facebook
          </button>

          <div className="divider">
            <span className="divider-line"></span>
            <span className="divider-line"></span>
          </div>

          <p className="terms">
            Please view our <a href="/privacy">Privacy Policy</a> to learn about the user data we collect.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;