import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import { deleteDoc, query, collection, getDocs, where } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';
import '../css/DataDeletion.css';
import { deleteUser } from 'firebase/auth';

const DataDeletion = () => {
  const { userData } = useUser();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAccount = async () => {
    if (!userData || confirmationText.toLowerCase() !== 'delete') return;
    
    try {
      setIsDeleting(true);
      
      try {
        const userQuery = query(collection(db, 'users'), where('userID', '==', userData.userID));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0]; // Only one document should match
          await deleteDoc(userDoc.ref);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      
      //Fix the below method to account for "Requires Recent Login" issue.
      const user = auth.currentUser;
      if (user) {
        await deleteUser(user);
      }

      setShowSuccess(true);
      setTimeout(() => {
        window.location.href = '/';
        auth.signOut();
      }, 10000);
    } catch (error) {
      console.error('Error deleting account:', error);
      setIsDeleting(false);
    }
  };

  return (
    <div className="payment-status-container">
      <div className="payment-canceled">

        {/* Sign-in Prompt */}
        {!userData && (
          <div className="modern-popup-overlay">
            <div className="modern-popup-content">
              <h2>Authentication Required</h2>
              <p>Please sign in to delete your account data.</p>
              <div className="popup-buttons">
                <Link to="/" className="home-link">
                  Home
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* Delete Account */}
        {userData && (
          <>
            <h1>Data/Account Deletion</h1>
            <button 
              onClick={() => setShowDeletePopup(true)}
              className="delete-account-btn"
            >
              Delete Account
            </button>
          </>
        )}

        {/* Delete Confirmation Popup */}
        {showDeletePopup && userData && (
          <div className="modern-popup-overlay">
            <div className="modern-popup-content">
              <h2>Confirm Account Deletion</h2>
              <p className="warning-text">
                ⚠️ This action is permanent and cannot be undone. All your data will be erased immediately.
              </p>
              
              <label>
                Type DELETE to erase all user data:
                <input
                  type="text"
                  value={confirmationText}
                  onChange={(e) => setConfirmationText(e.target.value)}
                  placeholder="Type DELETE here"
                  className="confirmation-input"
                />
              </label>

              <div className="popup-buttons">
                <button
                  onClick={handleDeleteAccount}
                  disabled={confirmationText.toLowerCase() !== 'delete' || isDeleting}
                  className="confirm-delete-btn"
                >
                  {isDeleting ? 'Deleting...' : 'Permanently Delete Account'}
                </button>
                <button
                  onClick={() => setShowDeletePopup(false)}
                  className="cancel-delete-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Success Message */}
        {showSuccess && (
          <div className="modern-popup-overlay">
            <div className="modern-popup-content success-message">
              <h2>Account Deleted Successfully</h2>
              <p>All your data has been permanently removed from our systems.</p>
              <p>If this was an error, please submit a ticket with your email address via the <Link to="/contact">Contact Us </Link> page.</p>
            </div>
          </div>
        )}        
      </div>
    </div>
  );
};

export default DataDeletion;