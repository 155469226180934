import { useState } from 'react';
import './css/App.css';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import AccessPage from './components/Access';
import ContactPage from './components/Contact';
import DataDeletion from './components/DataDeletion';
import PrivacyPage from './components/Privacy';
import HomePage from './components/Home';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCanceled from './components/PaymentCancelled';
import Login from './components/Login';

function App() 
{ 
  const [seen, setSeen] = useState(false)
  function togglePop () {
      setSeen(!seen);
  };

  return (
    <div id="RootApplicationContainer">
      <Header toggleLogin={togglePop} />
      {seen && <Login toggleLogin={togglePop} />}
      <Routes>
      <Route path="/" element={<HomePage toggleLogin={togglePop} />} /> 
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/data-deletion" element={<DataDeletion/>} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/access" element={<AccessPage toggleLogin={togglePop} />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-canceled" element={<PaymentCanceled />} />
      </Routes>
    </div>
  );
}

export default App;