import React from 'react';
import '../css/Note.css';

type Props = {
  actualValue: string;
  degree: string;
  pianoType: string;
  colour: string;
  note: string;
  highlightedClass?: string; // Optional prop for the highlighting class
}

function CalculateFullNoteValue(note: string, degree: string){
  if(degree.toUpperCase() == degree){
    return `${note}\n${degree}`
  } else{
    return `${note}m\n${degree}`
  }
}

const Note: React.FC<Props> = ({ degree, colour, note, pianoType, highlightedClass, actualValue }) => {
  const baseClass = colour === 'white' || colour === 'black' ? colour : 'white';
  const noteClass = `${baseClass} ${highlightedClass || ''}`.trim();

  //Correct top alignment for notes that aren't highlighted with a degree.
  if(!degree){
    degree = '\n';
  }

  return (
    <span>
      <input type="button" value={CalculateFullNoteValue(actualValue || note.slice(0, -1), degree)} id={`${note} ${pianoType}`} className={noteClass}/>
    </span> 
  );
};

export default Note;
