import '../css/Access.css';
import { auth } from '../firebaseConfig';
import { loadStripe } from '@stripe/stripe-js';
import { Timestamp } from 'firebase/firestore';
import { useUser } from './UserContext';

interface AccessPageProps {
  toggleLogin: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

function daysLeftInTrial(firstLoginDate: any): string {
  if (firstLoginDate instanceof Timestamp) {
    firstLoginDate = firstLoginDate.toDate();
  } else {
    firstLoginDate = new Date(firstLoginDate);
  }

  const currentDate = new Date();
  const trialEndDate = new Date(firstLoginDate);
  trialEndDate.setDate(trialEndDate.getDate() + 12); // Add 12 days to first login date

  // Calculate remaining time
  const differenceInMs = trialEndDate.getTime() - currentDate.getTime();
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const daysLeft = Math.ceil(differenceInMs / millisecondsPerDay);

  return daysLeft > 0 ? `${daysLeft} day${daysLeft > 1 ? "s" : ""}` : "0 days";
}

const AccessPage: React.FC<AccessPageProps> = ({ toggleLogin }) => {
  const { userData } = useUser();

  const handlePayment = async () => {
    const stripe = await stripePromise;
    const user = auth.currentUser;

    if (!user) {
      alert('Please sign in first!');
      return;
    }

    // Call your cloud function to create Checkout Session
    const response = await fetch('https://createpayment-au65fapsnq-ts.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await user.getIdToken()}`
      },
      body: JSON.stringify({
        userId: user.uid,
        priceId: 'price_1QjvgkP4QMc1tShmbQIhSBEW'
      })
    });

    const session = await response.json();
    
    // Redirect to Stripe Checkout
    await stripe!.redirectToCheckout({
      sessionId: session.sessionId
    });
  };

  return (
    <div>
      <h1 className="page-title">Application Access</h1>
      {userData ? (
        userData.paymentStatus === 'Paid' ? (
          <p>This account already has application access.</p>
        ) : (
          <div className="page-container">
            {daysLeftInTrial(userData.firstLogin) ? (
              <>
                <p className="page-description">
                  Enjoy all application features at no cost for the first twelve days. 
                  <br /><br />
                  You have {daysLeftInTrial(userData.firstLogin)} left in your trial. <br /> Once your trial expires, permanent access can be purchased below for $3.00AUD
                </p>
                <button className="upgrade-button" onClick={handlePayment}>
                  Checkout via Stripe
                </button>
              </>
            ) : (
              <>
                <p className="page-description">
                  Your free trial has expired, permanent access can be purchased below for $3.00AUD
                </p>
                <button className="upgrade-button" onClick={handlePayment}>
                  Checkout via Stripe
                </button>
              </>
            )}
          </div>
        )
      ) : (
        <>
           <a onClick={toggleLogin}>Sign In</a> to purchase application access.
        </>
      )}
    </div>
  );
};

export default AccessPage;