import { Link } from 'react-router-dom';

const PaymentCanceled = () => {
  return (
    <div className="payment-status-container">
      <div className="payment-canceled">
        <h1>❌ Payment Canceled</h1>
        <p>Your payment was not completed.</p>
      </div>
    </div>
  );
};

export default PaymentCanceled;