import React from 'react';
import { auth } from '../firebaseConfig'; // Adjust the path as needed
import { signOut } from 'firebase/auth';
import '../css/Header.css';
import '../css/Login.css'
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';

interface HeaderProps {
  toggleLogin: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggleLogin }) => {
  const { userData } = useUser();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <header className="Header-container">
      <nav>
        <Link className="headerNavigationButton" to="/">Home</Link>
        <Link className="headerNavigationButton" to="/contact">Contact Us</Link>
        <Link className="headerNavigationButton" to="/privacy">Privacy</Link>
        <Link className="headerNavigationButton" to="/access">Access</Link>
      </nav>
      <div>
        {userData ? (
          <>
            <button className='signOutButton' onClick={handleSignOut}>Sign Out</button>
          </>
        ) : (
          <button className='signInButton' onClick={toggleLogin}>Sign In</button>
        )}
      </div>
    </header>
  );
}

export default Header;