import React, { Component, useEffect, useState } from 'react';
import './css/App.css';
import './css/Piano.css';
import { notes, CalculateInversionSuffix } from './helpers';
import Piano from './components/Piano';

const noteList = [
  'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B',
];

interface ChordPianoState {
  inversion: number;
  chordType: string;
  lastNote: string;
  currentChord: string;
  noteInformation: { id: string; isPrimary: boolean; degree: string; actualValue: string }[];
}

interface VisibilityState {
  inversionSelector: true | false;
  chordSelector: true | false;
  lastChordNote: true | false;
  currentChord: true | false;
}

function Highlight(startingNote: string, inversion: number, pianoOctaves: number): { id: string; isPrimary: boolean; degree: string; actualValue: string  }[] {
  const noteInformation: { id: string; isPrimary: boolean; degree: string; actualValue: string }[] = [];
  const selector = document.getElementById('chordSelector') as HTMLInputElement;

  const findNoteIndex = (note: string) => notes.findIndex(noteObject => noteObject.note === note);
  const pressedNoteIndex = findNoteIndex(startingNote.slice(0, -1));

  notes.forEach((noteObject, index) => {
    if (noteObject.note === startingNote.slice(0, -1)) {
      const chordIntervals = new Map<string, number[]>([
        ['Major', [0, 4, 7]],
        ['Minor', [0, 3, 7]],
        ['Diminished', [0, 3, 6]],
        ['Seventh', [0, 4, 7, 10]],        
        ['Major Seventh', [0, 4, 7, 11]],
        ['Minor Seventh', [0, 3, 7, 10]],
      ]);

      const intervals = chordIntervals.get(selector.value) || [];
      let octaveOffset = Number(startingNote.at(-1));
      let hasOctaveOverflown = false;

      intervals.forEach((interval: number) => {   
        if (pressedNoteIndex + interval >= 12 && !hasOctaveOverflown) {
          octaveOffset++;
          hasOctaveOverflown = true;
        }
        const noteIndex = (index + interval) % 12;
        const noteID = `${notes[noteIndex].note}${octaveOffset} chord`;
        noteInformation.push({ id: noteID, isPrimary: true, degree: '', actualValue: notes[noteIndex].note });
      });     


      if(inversion !== 0){
        for(let i = 0; i < inversion; i++){
          const noteOctave = parseInt(noteInformation[i].id.match(/\d+/g)![0]);
          noteInformation[i].id = noteInformation[i].id.replace(noteOctave.toString(), (noteOctave + 1).toString());
        }

        noteInformation.forEach(note => {
          const noteOctave = parseInt(note.id.match(/\d+/g)![0]);
          note.id = note.id.replace(noteOctave.toString(), (noteOctave - 1).toString());
        });          
      }


      for (let i = 1; i <= pianoOctaves; i++) {
        intervals.forEach((interval: number) => {
          const noteIndex = (index + interval) % 12;
          const noteID = `${notes[noteIndex].note}${i} chord`;
      
          const noteExists = noteInformation.find(note => note.id === noteID);
      
          if (!noteExists) {
            noteInformation.push({ id: noteID, isPrimary: false, degree: '', actualValue: notes[noteIndex].note });
          }
        });
      }
    }    
  });

  return noteInformation;
}


const ChordPiano: React.FC = () => {
  const [pianoOctaves, setPianoOctaves] = useState<number>(0);
  const [inversionOptions, setInversionOptions] = useState<number[]>([0, 1, 2, 3]);

  const [pianoState, setPianoState] = useState<ChordPianoState>({ 
    inversion: 0,
    chordType: 'Major',
    lastNote: '', 
    currentChord: '', 
    noteInformation: [] 
  });

  const [visibility, setVisibility] = useState<VisibilityState>({
    inversionSelector: false,
    chordSelector: false,
    lastChordNote: false,
    currentChord: false,
  });

  const handleChordSelectorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selection = event.target.value;

    // Update the current chord state, which will trigger the useEffect to update inversion options
    setPianoState(prevState => ({
      ...prevState,
      chordType: selection
    }));
  };

  useEffect(() => {
    // Step 2
    // Logic to update inversion options based on the current chord
    const updateInversionOptions = () => {
      const chordType = pianoState.chordType;
      switch (chordType) {
        case 'Major':
        case 'Minor':
          setInversionOptions([0, 1, 2]); // Triads have up to second inversion
          break;
        case 'Seventh':
        case 'Major Seventh':
        case 'Minor Seventh':
          setInversionOptions([0, 1, 2, 3]); // Seventh chords have up to third inversion
          break;
        default:
          setInversionOptions([0]); // Default to root position for other cases or undefined chords
      }
    };

    updateInversionOptions();
  }, [pianoState.chordType]); // Depend on the currentChord state
  
  useEffect(() => {
    const handleNoteClick = (noteId: string) => {
      const note = noteId.split(' ')[0];
      const chordSelectorValue = (document.getElementById('chordSelector') as HTMLInputElement).value;
      const inversion = parseInt((document.getElementById('inversionSelector') as HTMLInputElement).value);

      setVisibility({
        inversionSelector: true,
        chordSelector: true,
        lastChordNote: true,
        currentChord: true
      });


      setPianoState(prevState => ({
        ...prevState,
        inversion: inversion,
        lastNote: note,
        currentChord: `${note.slice(0, -1)} ${chordSelectorValue}`,
        noteInformation: Highlight(note, inversion, pianoOctaves)
      }));
    };

    const eventListeners: { element: HTMLElement; listener: () => void }[] = [];

    noteList.forEach(note => {
      for (let octave = 1; octave <= pianoOctaves; octave++) {
        const noteId = `${note}${octave} chord`;
        const element = document.getElementById(noteId);
        if (element) {
          const listener = () => handleNoteClick(noteId);
          element.addEventListener('mousedown', listener);
          element.addEventListener('touchstart', listener);
          eventListeners.push({ element, listener });
        }
      }
    });

    return () => {
      eventListeners.forEach(({ element, listener }) => {
        element.removeEventListener('mousedown', listener);
        element.removeEventListener('touchstart', listener);
      });
    };
  }, [pianoOctaves]);

  return (
    <>
      <select 
        name="chordSelector" 
        id="chordSelector" 
        className={visibility.chordSelector ? 'is-visible' : ''}
        onChange={handleChordSelectorChange}
      >        
        <option value="Major">Major</option>
        <option value="Minor">Minor</option>
        <option value="Diminished">Diminished (°)</option>
        <option value="Major Seventh">Major Seventh (maj7)</option>
        <option value="Minor Seventh">Minor Seventh (min7)</option>
        <option value="Seventh">Seventh (7)</option>
      </select>
      <select 
      name="inversionSelector" 
      id="inversionSelector" 
      className={visibility.inversionSelector ? 'is-visible' : ''}>
  {inversionOptions.map(option => (
    <option key={option} value={option}>
      {option === 0 ? 'Root Position' : `${CalculateInversionSuffix(option)} Inversion`}
    </option>
  ))}
</select>

      <div id="currentChord" className={visibility.currentChord ? 'is-visible' : ''}>
        {`Chord: ${pianoState.currentChord}`}
      </div>
      <div id="lastChordNote" className={visibility.lastChordNote ? 'is-visible' : ''}>
        {`Last Note: ${pianoState.lastNote}`}
      </div>
      <br />
      <div id="chordPianoWrapper">
        <Piano notes={notes} pianoType="chord" setPianoOctaves={setPianoOctaves} noteInformation={pianoState.noteInformation} />
      </div>
    </>
  );

}

export default ChordPiano;

